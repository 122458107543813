<template>
  <div class="mtDiaog">
    <div class="ban">
      <el-button type="primary" @click="uplowd"
        >立即下载<el-icon><Download /></el-icon
      ></el-button>
    </div>
    <!-- <iframe
      src="https://academy.qmfquant.com/archives/7664"
      width="100%"
      height="1400px;"
      scrolling="no"
      border="0"
      frameborder="no"
    ></iframe> -->
    <el-divider> 强大的量化投研平台 </el-divider>
    <ul class="text">
      <li>
        <img src="../image/1.png" alt="" />
        <p>多语言编程</p>
        <p>支持python,javaScrit,麦语言无束缚编程</p>
      </li>
      <li>
        <img src="../image/1.png" alt="" />
        <p>多线程回测</p>
        <p>支持多品种，多周期，多模型组合回测，快的不仅仅是一步</p>
      </li>
      <li>
        <img src="../image/1.png" alt="" />
        <p>强大的库工具支持</p>
        <p>可高度灵活调用如pandas、numpy等第三方库做舆情、外部数据处理</p>
      </li>
      <li>
        <img src="../image/1.png" alt="" />
        <p>指标模型联调</p>
        <p>期魔方全面支持指标模型联调交互，策略可识别加密指标数组</p>
      </li>
    </ul>
    <el-divider> 期魔方，不仅仅只有量化 </el-divider>
    <div class="car">
      <el-card shadow="always">
        <div class="carTop">
          <img src="../image/bom.png" alt="" />
          <span>多屏预警</span>
        </div>
        <div class="carText">可自定义多屏监控，满足设置信号时，及时提醒</div>
      </el-card>
      <el-card shadow="always">
        <div class="carTop">
          <img src="../image/bom.png" alt="" />
          <span>精选指标库</span>
        </div>
        <div class="carText">
          包括订单流、PivotPoints等更专业多元的指标库,用专业替代盲猜
        </div>
      </el-card>
      <el-card shadow="always">
        <div class="carTop">
          <img src="../image/bom.png" alt="" />
          <span>盘手训练</span>
        </div>
        <div class="carText">灵活的盘手训练引擎，快速验证策略的可能性</div>
      </el-card>
      <el-card shadow="always">
        <div class="carTop">
          <img src="../image/bom.png" alt="" />
          <span>风险管理</span>
        </div>
        <div class="carText">
          全方位管理账户，无论在任何终端下单，您的账户都将得到风控保障
        </div>
      </el-card>
      <el-card shadow="always">
        <div class="carTop">
          <img src="../image/bom.png" alt="" />
          <span>模块量化</span>
        </div>
        <div class="carText">
          做量化，不一定要编写代码，期魔方提供套利、网格、套保等模块化量化方案，一键设置，方便快捷
        </div>
      </el-card>
      <el-card shadow="always">
        <div class="carTop">
          <img src="../image/bom.png" alt="" />
          <span>多账户管理</span>
        </div>
        <div class="carText">
          期魔方支持多账户管理工具，可多账户同时管理交易任务
        </div>
      </el-card>
      <el-card shadow="always">
        <div class="carTop">
          <img src="../image/bom.png" alt="" />
          <span>MT4TOCTP</span>
        </div>
        <div class="carText">
          实现MT4与期货账户联调，让MT4的EA直接用于期货量化
        </div>
      </el-card>
      <el-card shadow="always">
        <div class="carTop">
          <img src="../image/bom.png" alt="" />
          <span>多维分析</span>
        </div>
        <div class="carText">
          期魔方内置强大的多维分析模块，含跨周期、跨合约、跨市场、基差对比等模块，让分析更直观
        </div>
      </el-card>
    </div>
    <el-divider>
      魔方学院，你的投教启蒙老师，助力您成为市场中的明白人
    </el-divider>
    <div class="vid">
      <div class="imgBox">
        <div class="vidBox" @click="play">
          <el-icon><VideoPlay /></el-icon>
        </div>
        <img src="../image/video.png" alt="" />
      </div>
    </div>

    <ul class="bList">
      <li>
        <div>
          <p>策略超市</p>
          <p>
            期魔方精选低风险优质策略或指标，短线趋势高频都有哦，快来看看，哪一款更适合你？
          </p>
        </div>
      </li>
      <li>
        <div>
          <p>名师专栏</p>
          <p>
            一枝独秀不是春，百花齐放春满园, 名师引领, 共促成长,
            来魔方学院，重新理解市场
          </p>
        </div>
      </li>
      <li>
        <div>
          <p>交易大赛</p>
          <p>
            有技术，没有伯乐赏识？快来展示你的交易惊艳才华吧，让大家一睹风采，我们相信，民间真有高手，或许就是你哦，胜出者有奖品和奖金拿哦
          </p>
        </div>
      </li>
      <li>
        <div>
          <p>Python量化学习</p>
          <p>
            0基础,不会编程,没有经验？没关系,魔方学院助力您0基础学习Python量化,从入门到精通
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    uplowd() {
      location.href = "https://academy.qmfquant.com/qmf.zip";
    },
    play() {
      window.open(
        "https://www.qmfquant.com/wp-content/uploads/2023/04/890089b2a3151013.mp4",
        "_blank"
      );
    },
  },
};
</script>
<style scoped>
.mtDiaog {
  width: 100%;
  height: 100%;
}
@media (min-width: 1200px) {
  .el-divider {
    margin: 55px 0;
  }
  .el-divider >>> .el-divider__text {
    font-size: 32px;
    color: #212529;
    font-weight: bold;
    white-space: nowrap;
    background-color: #f9f9f9;
  }
  .ban {
    width: 100%;
    height: 800px;
    background-image: url("../image/ban.png");
    background-size: 100% 100%;
    position: relative;
  }

  .ban button {
    width: 170px;
    height: 46px;
    font-size: 24px;
    font-weight: bold;
    background: #5794d9;
    border: none;
    position: absolute;
    right: 50%;
    margin-right: -85px;
    border-radius: 10px;
    bottom: 1rem;
  }
  .ban button:hover {
    background: #2473cb;
  }
  .ban button .el-icon {
    font-size: 32px;
  }
  .text {
    width: 1200px;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #dbdbdb;
    padding-top: 10px;
  }
  .text li {
    width: 25%;
    height: 280px;
    text-align: center;
  }
  .text li p:nth-child(2) {
    font-size: 22px;
    font-weight: 700;
    margin: 20px 0;
    margin-top: 10px;
    color: #023378;
  }
  .text li p:nth-child(3) {
    margin: 0 auto;
    width: 80%;
    font-size: 14px;
    color: #555;
    text-align: justify;
  }
  .car {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .el-card {
    width: 24%;
    margin-bottom: 10px;
  }
  .el-card .carTop {
    height: 50px;
    display: flex;
    align-items: center;
    color: #303133;
    font-weight: bold;
    font-size: 16px;
  }
  .el-card .carTop img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  .el-card .carText {
    color: #717171;
    font-size: 12px;
  }

  .vid .imgBox {
    width: 1200px;
    margin: 0 auto;
    border-radius: 5px;
    height: 570px;
    position: relative;
    border: 1px solid #161616;
    margin-bottom: 30px;
  }
  .vid .imgBox .vidBox {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99;
    width: 50px;
    height: 50px;
    background: rgb(255 255 255 / 90%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -25px;
    margin-top: -20px;
    cursor: pointer;
  }
  .vid .imgBox .vidBox .el-icon {
    font-size: 40px;
    color: #515151;
  }
  .vid .imgBox img {
    width: 100%;
    height: 100%;
  }
  .bList {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .bList li {
    width: 24%;
    background-image: url("../image/b1.png");
    background-size: 100% 100%;
    color: #f3f3f3;
  }
  .bList li > div {
    width: 100%;
    height: 100%;
    background-color: rgb(97 97 97 / 80%);
    padding: 10px;
    box-sizing: border-box;
  }
  .bList li > div p:nth-child(1) {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .bList li > div p:nth-child(2) {
    font-size: 12px;
    margin: 0;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .el-divider {
    margin: 1.5rem 0;
  }
  .el-divider >>> .el-divider__text {
    font-size: 22px;
    color: #212529;
    font-weight: bold;
    background-color: #f9f9f9;
    white-space: nowrap;
  }
  .ban {
    width: 100%;
    height: 400px;
    background-image: url("../image/ban.png");
    background-size: 100% 400px;
    position: relative;
  }

  .ban button {
    width: 9rem;
    height: 36px;
    font-size: 18px;
    font-weight: bold;
    background: #5794d9;
    border: none;
    position: absolute;
    right: 50%;
    margin-right: -85px;
    border-radius: 10px;
    bottom: 1rem;
    display: none;
  }
  .ban button:hover {
    background: #2473cb;
  }
  .ban button .el-icon {
    font-size: 28px;
  }
  .text {
    width: 98vw;
    margin: 1rem auto;
    display: flex;
    border: 1px solid #dbdbdb;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .text li {
    width: 25%;
    text-align: center;
  }
  .text li p:nth-child(2) {
    font-size: 18px;
    font-weight: 700;
    margin: 1rem 0;
    margin-top: 10px;
    color: #023378;
  }
  .text li p:nth-child(3) {
    margin: 0 auto;
    width: 80%;
    font-size: 14px;
    color: #555;
    text-align: justify;
  }
  .car {
    width: 98vw;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .el-card {
    width: 24%;
    margin-bottom: 1rem;
  }
  .el-card .carTop {
    height: 50px;
    display: flex;
    align-items: center;
    color: #303133;
    font-weight: bold;
    font-size: 1rem;
  }
  .el-card .carTop img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  .el-card .carText {
    color: #717171;
    font-size: 14px;
    text-align: justify;
  }

  .vid .imgBox {
    width: 98vw;
    margin: 0 auto;
    border-radius: 5px;
    height: 360px;
    position: relative;
    border: 1px solid #161616;
    margin-bottom: 30px;
  }
  .vid .imgBox .vidBox {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99;
    width: 50px;
    height: 50px;
    background: rgb(255 255 255 / 90%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -25px;
    margin-top: -20px;
    cursor: pointer;
  }
  .vid .imgBox .vidBox .el-icon {
    font-size: 40px;
    color: #515151;
  }
  .vid .imgBox img {
    width: 100%;
    height: 100%;
  }
  .bList {
    width: 98vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .bList li {
    width: 24%;
    background-image: url("../image/b1.png");
    background-size: 100% 100%;
    color: #f3f3f3;
  }
  .bList li > div {
    width: 100%;
    height: 100%;
    background-color: rgb(97 97 97 / 80%);
    padding: 10px;
    box-sizing: border-box;
  }
  .bList li > div p:nth-child(1) {
    font-size: 16px;
    margin-bottom: 30px;
    font-weight: bold;
  }
  .bList li > div p:nth-child(2) {
    font-size: 14px;
    margin: 0;
  }
}
@media (max-width: 768px) {
  .el-divider {
    margin: 1rem 0;
  }
  .el-divider >>> .el-divider__text {
    font-size: 0.8rem;
    color: #212529;
    font-weight: bold;
    background-color: #f9f9f9;
    white-space: nowrap;
  }
  .ban {
    width: 100%;
    height: 13rem;
    background-image: url("../image/ban.png");
    background-size: 100% 13rem;
    position: relative;
  }

  .ban button {
    width: 5.5rem;
    height: 2rem;
    font-size: 0.8rem;
    font-weight: bold;
    background: #5794d9;
    border: none;
    position: absolute;
    right: 50%;
    margin-right: -2.5rem;
    border-radius: 10px;
    bottom: 1rem;
    display: none;
  }
  .ban button:hover {
    background: #2473cb;
  }
  .ban button .el-icon {
    font-size: 1.2rem;
  }
  .text {
    width: 98vw;
    margin: 1rem auto;
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex-wrap: wrap;
  }
  .text li {
    width: 50%;
    text-align: center;
  }
  .text li img {
    width: 75px;
    height: 76px;
  }
  .text li p:nth-child(2) {
    font: size 1rem;
    margin: 0.5rem 0;
    color: #023378;
  }
  .text li p:nth-child(3) {
    margin: 0 auto 1rem;
    width: 80%;
    font-size: 0.7rem;
    color: #555;
    text-align: justify;
  }
  .car {
    width: 98vw;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .el-card {
    width: 49%;
    margin-bottom: 1rem;
  }
  .el-card .carTop {
    height: 2rem;
    display: flex;
    align-items: center;
    color: #303133;
    font-weight: bold;
    font-size: 0.8rem;
  }
  .el-card .carTop img {
    width: 30px;
    height: 30px;
    margin-right: 0.5rem;
  }
  .el-card .carText {
    color: #717171;
    font-size: 0.6rem;
    text-align: justify;
  }

  .vid .imgBox {
    width: 98vw;
    margin: 0 auto;
    border-radius: 5px;
    height: 180px;
    position: relative;
    border: 1px solid #161616;
    margin-bottom: 1.5rem;
  }
  .vid .imgBox .vidBox {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99;
    width: 50px;
    height: 50px;
    background: rgb(255 255 255 / 90%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -25px;
    margin-top: -20px;
    cursor: pointer;
  }
  .vid .imgBox .vidBox .el-icon {
    font-size: 40px;
    color: #515151;
  }
  .vid .imgBox img {
    width: 100%;
    height: 100%;
  }
  .bList {
    width: 98vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .bList li {
    width: 49%;
    background-image: url("../image/b1.png");
    background-size: 100% 100%;
    color: #f3f3f3;
    height: 8rem;
    margin-bottom: 1rem;
  }
  .bList li > div {
    width: 100%;
    height: 100%;
    background-color: rgb(97 97 97 / 80%);
    padding: 0.5rem;
    box-sizing: border-box;
  }
  .bList li > div p:nth-child(1) {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }
  .bList li > div p:nth-child(2) {
    font-size: 0.5rem;
    margin: 0;
  }
}
</style>